/* global window */
import './store_logo.scss';

var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');
	const BRAND_LOGO_CLASSES = [".manufacturer-logos", ".manufacturer-logo", ".recommendation-manufacturer-data", ".product-manufacturer-data", ".part-manufacturer", ".recommendation"];

	function addImageErrorEvent() {
		rp$(BRAND_LOGO_CLASSES.join(', ')).find('img').each(function() {
			if (this.complete && this.naturalWidth === 0 && this.naturalHeight === 0) {
				// hide image if not loaded
				rp$(this).hide();
			}
		}).on('error', function() {
			// hide image on error
			rp$(this).hide();
		});
	}

	$onReady({rp$, window,
		label: 'store_logo',
		fn: function () {
			// Watch for changes in the DOM
			rp$(document).on('DOMNodeInserted', function(e) {
				// Check if the inserted node contains images with specified classes
				if (rp$(e.target).is(BRAND_LOGO_CLASSES.join(', ')) || rp$(e.target).find(BRAND_LOGO_CLASSES.join(', ')).length > 0) {
					// Add onerror event to the newly inserted images
					addImageErrorEvent();
				}
			});
			
			// Initial setup to handle existing images
			addImageErrorEvent();
		},
	});
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}